// extracted by mini-css-extract-plugin
export var heroScroll = "menubar-module--heroScroll--piFs7";
export var header = "menubar-module--header--QsK8w";
export var not = "menubar-module--not---AN1g";
export var scrolled = "menubar-module--scrolled--6CZWb";
export var active = "menubar-module--active--2bbz4";
export var grid = "menubar-module--grid--cK0gu";
export var brand = "menubar-module--brand--Q9M+q";
export var nav = "menubar-module--nav--lKapg";
export var globo = "menubar-module--globo--kH4mL";
export var hamburgerBtn = "menubar-module--hamburger-btn--RVm4b";
export var close = "menubar-module--close--ToMbv";
export var logoOpened = "menubar-module--logoOpened--m1BDr";
export var rotateDesktop = "menubar-module--rotate-desktop--bldUK";
export var rotateActive = "menubar-module--rotateActive--hMySu";
export var hidden = "menubar-module--hidden--MPsZU";
export var switchLanguage = "menubar-module--switchLanguage--oqMMH";
export var login = "menubar-module--login--IJCXa";
export var link = "menubar-module--link--ayQYy";
export var core = "menubar-module--core--rzyTa";