// extracted by mini-css-extract-plugin
export var revealSection = "animatedVideo-module--revealSection--dZtMB";
export var revealSticky = "animatedVideo-module--revealSticky--EHa7e";
export var revealPath = "animatedVideo-module--revealPath--VEBN-";
export var revealWrap = "animatedVideo-module--revealWrap--4HUgh";
export var dimasBarra = "animatedVideo-module--dimasBarra--nt+jT";
export var revealLogo = "animatedVideo-module--revealLogo--+5QwP";
export var revealEmbedWrap = "animatedVideo-module--revealEmbedWrap--Jrrta";
export var revealEmbedContain = "animatedVideo-module--revealEmbedContain--lklxw";
export var revealEmbed = "animatedVideo-module--revealEmbed--uOjdD";
export var video = "animatedVideo-module--video--PKRTE";