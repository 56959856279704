// extracted by mini-css-extract-plugin
export var hero = "home-module--hero--74SI2";
export var welcome = "home-module--welcome--2aI-f";
export var releases = "home-module--releases--FheaD";
export var blog = "home-module--blog--5AUEA";
export var practice = "home-module--practice--exVRc";
export var releasesEnterprises = "home-module--releasesEnterprises--xw0ye";
export var releasesContainer = "home-module--releasesContainer--HsKHl";
export var imageWrapper = "home-module--imageWrapper--PHdAq";
export var animationHover = "home-module--animationHover--cOwe3";
export var tag = "home-module--tag--PqADG";
export var numbersContainer = "home-module--numbersContainer--Cc009";
export var numbers = "home-module--numbers--QEUzf";
export var smallerList = "home-module--smallerList--tYNbi";
export var slidesWrapper = "home-module--slidesWrapper--RSmoF";
export var slide = "home-module--slide--ozb4s";
export var blogDimas = "home-module--blogDimas--nyBpG";
export var effect = "home-module--effect--Tpbe3";
export var blogDimasContainer = "home-module--blogDimasContainer--ecCbE";
export var videoModal = "home-module--video-modal--yRUWS";
export var videoCta = "home-module--video-cta--CzxTe";
export var smallerImage = "home-module--smallerImage--rWUGO";
export var arrow = "home-module--arrow--qDNIl";
export var rotate = "home-module--rotate--ofIw4";