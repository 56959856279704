import React, { FormEvent, useState, useEffect } from 'react'
import { Link, graphql, navigate, useStaticQuery } from 'gatsby'
import * as st from '@assets/styl/footer.module.styl'
import logo from '@assets/img/footer-logo.png'
import { useLanguage } from '../hooks'
import axios from 'axios'
import { translatePath } from '../translations'
import cn from 'classnames'
import arrow from '@assets/svg/arrow-down.svg'

type FormProps = {
  open?: boolean
  close: () => void
  states?: Array<{
    name: string
    cities: Array<{
      name: string
    }>
  }>
}

const onSubmit = (
  e: FormEvent,
  formData: any,
  loadingHandler: () => void,
  successHandler: () => void
) => {
  e.preventDefault()

  const dataToSend = {
    ...formData,
    email: formData.email,
    token_rdstation: '529cac2f361142d6e1232668a5dbe8f9',
  }

  loadingHandler()

  axios
    .post('https://www.rdstation.com.br/api/1.3/conversions', dataToSend, {
      headers: {
        'Content-type': 'application/json',
      },
    })
    .then((response) => {
      loadingHandler()
      if (response.status === 200) {
        successHandler()
        navigate('/sucesso')
      } else {
        console.error('Falha no envio para RD Station', response)
      }
    })
    .catch((error) => {
      loadingHandler()
      console.error('Erro ao enviar para RD Station', error)
    })
}

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allAddresses {
        nodes {
          location
        }
      }
      allConfigurations {
        nodes {
          phone
          facebook
          instagram
          linkedin
          youtube
        }
      }
    }
  `)
  const { t } = useLanguage()
  const location = data.allAddresses.nodes[0].location.split(/(.*?\d+ )/)
  const socials = data.allConfigurations.nodes[0]
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [isMobile, setIsMobile] = useState(false)

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1199)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const [submitLabel, setSubmitLabel] = useState(t('Contact.send'))
  const defaultData = {
    email: '',
    permissao: false,
    identificador: 'Dimas Construções Site - Newsletter',
  }
  const [form, setForm] = useState({ ...defaultData })

  return (
    <footer className={st.core}>
      <div className={st.firstPart}>
        <img src={logo} alt="Logo dimas" />
        <ul className={st.navFooter}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <div onClick={() => handleClick(0)}>
              {isMobile ? (
                <>
                  <span>{t('Footer.enterprises')}</span>
                  <img
                    src={arrow}
                    alt="arrow"
                    className={activeIndex === 0 ? st.rotateActive : ''}
                  />
                </>
              ) : (
                <Link to={translatePath('/contato/')}>
                  {t('Footer.enterprises')}
                </Link>
              )}
            </div>
            {isMobile ? (
              <ul className={activeIndex === 0 ? st.active : st.hidden}>
                <li>
                  <Link to={translatePath('/empreendimentos/lancamentos/')}>
                    {t('Footer.releases')}
                  </Link>
                </li>
                <li>
                  <Link to={translatePath('/empreendimentos/entregues/')}>
                    {t('Footer.handedOut')}
                  </Link>
                </li>
                <li>
                  <a
                    href="https://www.dimasconstrucoes.com.br/revendas/"
                    target="_blank"
                  >
                    {t('MenuBar.resale')}
                  </a>
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <Link to={translatePath('/empreendimentos/lancamentos/')}>
                    {t('Footer.releases')}
                  </Link>
                </li>
                <li>
                  <Link to={translatePath('/empreendimentos/entregues/')}>
                    {t('Footer.handedOut')}
                  </Link>
                </li>
                <li>
                  <a
                    href="https://www.dimasconstrucoes.com.br/revendas/"
                    target="_blank"
                  >
                    {t('MenuBar.resale')}
                  </a>
                </li>
              </ul>
            )}
          </li>
          <li>
            <Link to={translatePath('/jeito-dimas/')}>
              {t('Footer.dimasWay')}
            </Link>
          </li>
          <li>
            <a href="https://dimasconstrucoes.com.br/blog/" target="_blank">
              Blog
            </a>
          </li>
          <li>
            <div onClick={() => handleClick(1)}>
              {isMobile ? (
                <>
                  <span>{t('Footer.contact')}</span>
                  <img
                    src={arrow}
                    alt="arrow"
                    className={activeIndex === 1 ? st.rotateActive : ''}
                  />
                </>
              ) : (
                <Link to={translatePath('/contato/')}>
                  {t('Footer.contact')}
                </Link>
              )}
            </div>
            {isMobile ? (
              <ul className={activeIndex === 1 ? st.active : st.hidden}>
                <li>
                  <Link to={translatePath('/contato/')}>
                    {t('Footer.service')}
                  </Link>
                </li>
                <li>
                  <Link to={translatePath('/contato/trabalhe-conosco/')}>
                    {t('Footer.workWithUs')}
                  </Link>
                </li>
                <li>
                  <Link to={translatePath('/')}>
                    {t('MenuBar.becomeSupplier')}
                  </Link>
                </li>
                <li>
                  <Link to={translatePath('/contato/faq/')}>FAQ</Link>
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <Link to={translatePath('/contato/')}>
                    {t('Footer.service')}
                  </Link>
                </li>
                <li>
                  <Link to={translatePath('/contato/trabalhe-conosco/')}>
                    {t('Footer.workWithUs')}
                  </Link>
                </li>
                <li>
                  <Link to={translatePath('/')}>
                    {t('MenuBar.becomeSupplier')}
                  </Link>
                </li>
                <li>
                  <Link to={translatePath('/contato/faq/')}>FAQ</Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div onClick={() => handleClick(2)}>
              {isMobile ? (
                <>
                  <span>{t('Footer.login')}</span>
                  <img
                    src={arrow}
                    alt="arrow"
                    className={activeIndex === 2 ? st.rotateActive : ''}
                    width="256"
                    height="269"
                  />
                </>
              ) : (
                <a
                  href="https://dimas.cvcrm.com.br/cliente/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Footer.login')}
                </a>
              )}
            </div>
            {isMobile ? (
              <ul className={activeIndex === 2 ? st.active : st.hidden}>
                <li>
                  <a
                    href="https://app.winker.com.br/intra/default/login?wl=dimas"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('Footer.customerPortal')}
                  </a>
                </li>
                <li>
                  <Link to={translatePath('/acesse-condominio/')}>
                    {t('Footer.accessYourCondominium')}
                  </Link>
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <a
                    href="https://app.winker.com.br/intra/default/login?wl=dimas"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('Footer.customerPortal')}
                  </a>
                </li>
                <li>
                  <Link to={translatePath('/acesse-condominio/')}>
                    {t('Footer.accessYourCondominium')}
                  </Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
      <div className={st.secondPart}>
        <div className={st.address}>
          <span>{t('Footer.dimasHeadquarters')}</span>
          <p>
            Av. Presidente Kennedy, 815
            <br />
            Campinas, São José - SC
          </p>
          <span>(48) 3381 3031</span>
        </div>
        <div className={st.contact}>
          <span>{t('Footer.subscribe')}</span>
          <p className={st.subscribeDescription}>
            {t('Footer.news')
              .split('\n')
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < t('Footer.news').split('\n').length - 1 && <br />}
                </React.Fragment>
              ))}
          </p>

          <form
            action=""
            onSubmit={(e) =>
              onSubmit(
                e,
                form,
                () =>
                  setSubmitLabel(
                    submitLabel !== t('Contact.send')
                      ? t('Contact.send')
                      : t('Contact.loading')
                  ),
                () => {
                  setForm({ ...defaultData })
                  close()
                }
              )
            }
          >
            <div>
              <input
                className="btn-white"
                type="email"
                name="email"
                placeholder={t('Footer.placeHolder')}
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
              />
              <button className="btn-white"></button>
            </div>
            <div className={st.checkbox}>
              <div className={form.permissao ? st.checked : ''}>
                <input
                  type="checkbox"
                  id="Ouvidoria-permissao"
                  checked={form.permissao}
                  onChange={(e) =>
                    setForm({ ...form, permissao: e.target.checked })
                  }
                  required
                />
              </div>
              <label htmlFor="Ouvidoria-permissao">
                <p className={st.checkboxText}>
                  {t('Contact.terms.1')}{' '}
                  <a
                    target="_blank"
                    href="https://www.dimasconstrucoes.com.br/politica-de-privacidade/"
                  >
                    {t('Contact.terms.2')}
                  </a>{' '}
                  {t('Contact.terms.3')}{' '}
                  <a
                    target="_blank"
                    href="https://www.dimasconstrucoes.com.br/termos-de-uso/"
                  >
                    {t('Contact.terms.4')}
                  </a>
                  .
                </p>
              </label>
            </div>
          </form>
        </div>
      </div>
      <div className={st.thirdPart}>
        <ul>
          <li>
            <a href={socials.facebook} target="_blank">
              Facebook
            </a>
          </li>
          <li>
            <a href={socials.instagram} target="_blank">
              Instagram
            </a>
          </li>
          <li>
            <a href={socials.linkedin} target="_blank">
              Linkedin
            </a>
          </li>
          <li>
            <a href={socials.youtube} target="_blank">
              Youtube
            </a>
          </li>
        </ul>
        <div className={st.dimas}>
          <span>© Dimas 2024</span>
        </div>
      </div>
      <div className={st.tiki}>
        <a href="https://www.tiki.com.br" target="_blank">
          Tiki
        </a>
      </div>
    </footer>
  )
}
