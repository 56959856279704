// extracted by mini-css-extract-plugin
export var core = "footer-module--core--tMdIv";
export var firstPart = "footer-module--firstPart--DX1NW";
export var rotateActive = "footer-module--rotateActive--ubT0z";
export var navFooter = "footer-module--navFooter--eBVpm";
export var hidden = "footer-module--hidden--Tg7TE";
export var active = "footer-module--active--ndcU2";
export var secondPart = "footer-module--secondPart--G7r0N";
export var contact = "footer-module--contact--EIDQx";
export var checkbox = "footer-module--checkbox--VUwns";
export var checked = "footer-module--checked--savEL";
export var thirdPart = "footer-module--thirdPart--3zl3D";
export var dimas = "footer-module--dimas--di2J2";
export var tiki = "footer-module--tiki--4yvRj";
export var address = "footer-module--address--r13ZY";