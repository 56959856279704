import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import cn from 'classnames'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import * as st from '@assets/styl/home.module.styl'
import { useLanguage } from '../hooks'

gsap.registerPlugin(ScrollTrigger)

const AnimatedWelcome = () => {
  const sectionRef = useRef<HTMLElement>(null)
  const textRef = useRef<HTMLParagraphElement>(null)
  const [isMobile, setIsMobile] = useState(false)
  const { t } = useLanguage()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1199)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const textElement = textRef.current
    if (!textElement) return

    const rawText = t('Home.titleText')
    const lines = rawText.split('\n')
    textElement.innerHTML = ''

    const charElements: HTMLElement[] = []

    lines.forEach((line, lineIndex) => {
      line.split(' ').forEach((word, wordIndex) => {
        const span = document.createElement('span')
        span.textContent =
          word + (wordIndex < line.split(' ').length - 1 ? '\u00A0' : '')
        span.style.display = 'inline-block'
        textElement.appendChild(span)
        charElements.push(span)
      })

      if (lineIndex < lines.length - 1) {
        const br = document.createElement('br')
        textElement.appendChild(br)
      }
    })

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        pin: true,
        pinnedContainer: sectionRef.current,
        scrub: 1,
      },
    })
    tl.set(charElements, { 
      opacity: 0.5
    }) 
    tl.to(charElements, {
      color: '#181817',
      opacity: 1,
      stagger: 0.1,
    })
  }, [t])

  return (
    <section className={st.welcome} ref={sectionRef}>
      <div className={cn(st.wrapper)}>
        <p ref={textRef}>
          {t('Home.titleText')
            .split('\n')
            .map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index < t('Home.titleText').split('\n').length - 1 && <br />}
              </React.Fragment>
            ))}
        </p>
      </div>
    </section>
  )
}

export default AnimatedWelcome
