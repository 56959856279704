import React, { useEffect, useState, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import * as st from '@assets/styl/valuesTabs.module.styl'
import 'swiper/css'
import star from '@assets/svg/star.svg'
import cn from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'

import img1 from '@assets/img/scroll-01.jpg'
import img2 from '@assets/img/scroll-02.jpg'
import img3 from '@assets/img/scroll-03.jpg'
import img4 from '@assets/img/scroll-04.jpg'
import img5 from '@assets/img/scroll-05.jpg'
import { useLanguage } from '../hooks'

gsap.registerPlugin(ScrollTrigger)

const HorizontalScrollSection = () => {
  const { t } = useLanguage()
  const sectionRef = useRef<HTMLDivElement | null>(null)
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const [isMobile, setIsMobile] = useState(false)
  const swiperRef = useRef<any>(null)
  const tl = useRef<gsap.core.Timeline | null>(null)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1199)
    }

    window.addEventListener('resize', handleResize, { signal })
    handleResize()

    return () => {
      controller.abort()
    }
  }, [])

  const items = [
    {
      src: img1,
      number: '01',
      title: t('ValuesTabs.buildForPeople'),
      text: t('ValuesTabs.coreValue'),
    },
    {
      src: img2,
      number: '02',
      title: t('ValuesTabs.buildForCity'),
      text: t('ValuesTabs.positivelyImpact'),
    },
    {
      src: img3,
      number: '03',
      title: t('ValuesTabs.buildForTimeless'),
      text: t('ValuesTabs.developAproject'),
    },
    {
      src: img4,
      number: '04',
      title: t('ValuesTabs.buildForTechnology'),
      text: t('ValuesTabs.professionalProvision'),
    },
    {
      src: img5,
      number: '05',
      title: t('ValuesTabs.buildForCulture'),
      text: t('ValuesTabs.weWantToSay'),
    },
  ]

  useEffect(() => {
    if (!sectionRef.current || !scrollRef.current) return

    if (window.innerWidth > 1199) {
      const horizontalScrollWidth = scrollRef.current.scrollWidth
      const visibleWidth = scrollRef.current.offsetWidth
      if (!horizontalScrollWidth) return

      const paddingOffset = 120 * 2
      const scrollDistance =
        horizontalScrollWidth - visibleWidth + paddingOffset

      const startValue = window.innerWidth < 1600 ? '10% top' : 'top top'

      if (tl.current) {
        tl.current.kill()
      }

      tl.current = gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          pin: true,
          start: startValue,
          scrub: 1,
          pinnedContainer: sectionRef.current,
          end: `+=${scrollDistance}`,
          invalidateOnRefresh: true,
        },
      })

      tl.current.to(scrollRef.current, {
        x: -scrollDistance,
        ease: 'none',
      })
    }
  }, [isMobile])

  return (
    <>
      <section ref={sectionRef} className={st.sectionContainer}>
        {isMobile ? (
          <>
            <div className={st.sectionTitle}>
              <img
                src={star}
                alt="Imagem do asterisco"
                width="82"
                height="82"
              />
              <h2>
                {t('ValuesTabs.title.1')}
                <br />
                {t('ValuesTabs.title.2')}
                {t('ValuesTabs.title.3')}
              </h2>
            </div>
            <Swiper
              ref={swiperRef}
              breakpoints={{
                320: {
                  slidesPerView: 1.2,
                },
                767: {
                  slidesPerView: 2.2,
                },
              }}
              className={st.slider}
            >
              {items.map(({ src, title, number, text }, index) => {
                const isEven = index % 2 === 0
                return (
                  <SwiperSlide key={index}>
                    <div className={cn(st.item)}>
                      {isEven ? (
                        <>
                          <img
                            src={src}
                            alt="Imagens do scroll"
                            className={st.imageContainer}
                          />
                          <div className={st.textContainer}>
                            <span>{number}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={st.textContainer}>
                            <span>{number}</span>
                          </div>
                          <img
                            src={src}
                            alt="Imagens do scroll"
                            className={st.imageContainer}
                          />
                        </>
                      )}

                      <div className={st.textContainer}>
                        <h4>{title}</h4>
                        <p>{text}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </>
        ) : (
          <div ref={scrollRef} className={st.scrollContainer}>
            <div className={st.sectionTitle}>
              <img
                src={star}
                alt="Imagem do asterisco"
                width="82"
                height="82"
              />
              <h2>
                {t('ValuesTabs.title.1')}
                <br />
                {t('ValuesTabs.title.2')}
                {t('ValuesTabs.title.3')}
              </h2>
            </div>
            <div className={st.sectionContent}>
              {items.map(({ src, title, number, text }, index) => {
                const isEven = index % 2 === 0

                return (
                  <div className={cn(st.item)} key={index}>
                    {isEven ? (
                      <>
                        <img
                          src={src}
                          alt="Imagens do scroll"
                          className={st.imageContainer}
                        />
                        <div className={st.textContainer}>
                          <span>{number}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={st.textContainer}>
                          <span>{number}</span>
                        </div>
                        <img
                          src={src}
                          alt="Imagens do scroll"
                          className={st.imageContainer}
                        />
                      </>
                    )}

                    <div className={st.textContainer}>
                      <h4>{title}</h4>
                      <p>{text}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </section>
    </>
  )
}

export default HorizontalScrollSection
