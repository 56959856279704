// extracted by mini-css-extract-plugin
export var sectionContainer = "differential-module--sectionContainer--xkCKY";
export var scrollContainer = "differential-module--scrollContainer--Qc1bZ";
export var sectionTitle = "differential-module--sectionTitle--FEwy4";
export var sectionContent = "differential-module--sectionContent--P17fE";
export var item = "differential-module--item--nTRrP";
export var slidesWrapper = "differential-module--slidesWrapper--R8pPK";
export var imageContainer = "differential-module--imageContainer--qYAbG";
export var textContainer = "differential-module--textContainer--kWBc4";
export var styledList = "differential-module--styledList--dxpHL";
export var logos = "differential-module--logos--6cFbV";
export var sectionItem = "differential-module--sectionItem--ITdIO";