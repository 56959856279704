import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'
import * as st from '@assets/styl/menubar.module.styl'
import arrow from '@assets/svg/arrow-down.svg'
import { useLanguage } from '../hooks'
import { translatePath } from '../translations'

import logo from '@assets/svg/logo.svg'

type Props = {
  pathname: string
  position?: string
}

type NavLinkProps = {
  to: string
  logo?: boolean
  children?: JSX.Element | string
  base?: string
  subLink?: boolean
}

const Menubar = ({ position = 'fixed', pathname }: Props) => {
  const [hamburgerActive, setHamburgerActive] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  if (pathname.startsWith('/en') || pathname.startsWith('/es'))
    pathname = pathname.slice(3)

  if (pathname === '' || pathname[pathname.length - 1] !== '/')
    pathname = `${pathname}/`

  const NavLink = ({
    to,
    logo,
    children,
    base,
    subLink = false,
  }: NavLinkProps) => (
    <Link
      to={to}
      className={cn(
        !subLink && st.link,
        Boolean(!logo && !subLink) && 'icon-menubar-link',
        to === '/'
          ? pathname === '/' && st.active
          : pathname.includes(base || to) && st.active
      )}
      onClick={() => setHamburgerActive(false)}
    >
      {children}
    </Link>
  )

  const [active, setActive] = useState(false)
  const [scrolledBeyondFold, setScrolledBeyondFold] = useState(false)
  const lastScrollY = useRef(0)

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY >= window.innerHeight) {
        setScrolledBeyondFold(true)
      } else {
        setScrolledBeyondFold(false)
        setActive(false)
      }

      if (scrolledBeyondFold) {
        if (window.scrollY > lastScrollY.current) {
          setActive(false)
        } else {
          setActive(true)
        }
      }

      lastScrollY.current = window.scrollY
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrolledBeyondFold])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1199)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const { t } = useLanguage()

  const handleAnchorClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const target = e.currentTarget
    const targetHref = target.getAttribute('href')
    const id = targetHref?.split('#')[1]

    if (!window.location.href.includes(targetHref)) return

    e.preventDefault()
    scrollToSection(id)
  }

  const scrollToSection = (id) => {
    const element = document.getElementById(id)

    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  return (
    <>
      <div
        className={cn(
          st.core,
          st[position],
          position === 'fixed' && !active && st.hidden,
          hamburgerActive && st.active
        )}
      >
        <header
          className={cn(
            st.header,
            'heroScroll',
            { [st.scrolled]: scrolledBeyondFold, [st.not]: !scrolledBeyondFold }, 
            { [st.active]: active }
          )}
        >
          <div className={st.grid}>
            <NavLink to="/" logo>
              <div className={st.brand}>
                <img
                  src={logo}
                  alt="Imagem do logo Dimas"
                  width={158}
                  height={23}
                />
              </div>
            </NavLink>
            {/* <div className={st.logoOpened}>
              <NavLink to="/" logo></NavLink>
            </div> */}
            <nav className={st.nav}>
              <ul>
                <li>
                  <div onClick={() => handleClick(0)}>
                    {isMobile ? (
                      <span>{t('MenuBar.enterprises')}</span>
                    ) : (
                      <NavLink
                        to={translatePath('/empreendimentos/lancamentos/')}
                        base={translatePath('/empreendimentos/')}
                      >
                        {t('MenuBar.enterprises')}
                      </NavLink>
                    )}
                    <img
                      src={arrow}
                      alt="arrow"
                      className={
                        activeIndex === 0 ? st.rotateActive : st.rotateDesktop
                      }
                    />
                  </div>
                  <ul className={activeIndex === 0 ? st.active : st.hidden}>
                    <li>
                      <NavLink
                        to={translatePath('/empreendimentos/lancamentos/')}
                        subLink
                      >
                        {t('MenuBar.releases')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={translatePath('/empreendimentos/entregues/')}
                        subLink
                      >
                        {t('MenuBar.handedOut')}
                      </NavLink>
                    </li>
                    <li>
                      <a
                        href="https://www.dimasconstrucoes.com.br/revendas/"
                        target="_blank"
                      >
                        {t('MenuBar.resale')}
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to={translatePath('/jeito-dimas/')}>
                    {t('MenuBar.dimasWay')}
                  </NavLink>
                </li>
                <li>
                  <a
                    href="https://dimasconstrucoes.com.br/blog/"
                    target="_blank"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <div onClick={() => handleClick(1)}>
                    {isMobile ? (
                      <span>{t('MenuBar.contact')}</span>
                    ) : (
                      <NavLink
                        to={translatePath('/contato/')}
                        base={translatePath('/contato/')}
                      >
                        {t('MenuBar.contact')}
                      </NavLink>
                    )}
                    <img
                      src={arrow}
                      alt="arrow"
                      className={
                        activeIndex === 1 ? st.rotateActive : st.rotateDesktop
                      }
                    ></img>
                  </div>
                  <ul className={activeIndex === 1 ? st.active : st.hidden}>
                    <li>
                      <NavLink to={translatePath('/contato/')} subLink>
                        {t('MenuBar.service')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={translatePath('/contato/trabalhe-conosco/')}
                        subLink
                      >
                        {t('MenuBar.workWithUs')}
                      </NavLink>
                    </li>
                    <li>
                      <Link
                        to={translatePath('/contato/#suppliers')}
                        onClick={handleAnchorClick}
                      >
                        {t('MenuBar.becomeSupplier')}
                      </Link>
                    </li>
                    <li>
                      <NavLink to={translatePath('/contato/faq/')} subLink>
                        FAQ
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className={st.login}>
                  <div onClick={() => handleClick(2)}>
                    <span className="icon-user">{t('MenuBar.login')}</span>
                    <img
                      src={arrow}
                      alt="arrow"
                      className={
                        activeIndex === 2 ? st.rotateActive : st.rotateDesktop
                      }
                    ></img>
                  </div>
                  <ul className={activeIndex === 2 ? st.active : st.hidden}>
                    <li>
                      <a
                        href="https://app.winker.com.br/intra/default/login?wl=dimas"
                        target="_blank"
                      >
                        {t('MenuBar.customerPortal')}
                      </a>
                    </li>
                    <li>
                      <NavLink
                        to={translatePath('/acesse-condominio/')}
                        subLink
                      >
                        {t('MenuBar.accessYourCondominium')}
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {isMobile && (
                  <li className={st.globo}>
                    <span className="icon-Globo"></span>
                    <ul className={st.switchLanguage}>
                      <li>
                        <a
                          href="/"
                          className={
                            process.env.GATSBY_LANGUAGE === 'pt'
                              ? st.active
                              : undefined
                          }
                        >
                          PT
                        </a>
                      </li>
                      <li>
                        <a
                          href="/en/"
                          className={
                            process.env.GATSBY_LANGUAGE === 'en'
                              ? st.active
                              : undefined
                          }
                        >
                          EN
                        </a>
                      </li>
                      <li>
                        <a
                          href="/es/"
                          className={
                            process.env.GATSBY_LANGUAGE === 'es'
                              ? st.active
                              : undefined
                          }
                        >
                          ES
                        </a>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
            </nav>
            {!isMobile && (
              <div className={st.globo}>
                <span className="icon-Globo"></span>
                <ul className={st.switchLanguage}>
                  <li>
                    <a
                      href="/"
                      className={
                        process.env.GATSBY_LANGUAGE === 'pt'
                          ? st.active
                          : undefined
                      }
                    >
                      PT
                    </a>
                  </li>
                  <li>
                    <a
                      href="/en/"
                      className={
                        process.env.GATSBY_LANGUAGE === 'en'
                          ? st.active
                          : undefined
                      }
                    >
                      EN
                    </a>
                  </li>
                  <li>
                    <a
                      href="/es/"
                      className={
                        process.env.GATSBY_LANGUAGE === 'es'
                          ? st.active
                          : undefined
                      }
                    >
                      ES
                    </a>
                  </li>
                </ul>
              </div>
            )}
            <button
              className={st.close}
              onClick={() => setHamburgerActive(false)}
            ></button>
            <button
              className={cn(st.hamburgerBtn, 'icon-hamburger')}
              onClick={() => setHamburgerActive(true)}
            ></button>
          </div>
        </header>
      </div>
    </>
  )
}

export default (props: Props) => (
  <div>
    <Menubar {...props} />
  </div>
)
