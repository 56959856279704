import { useEffect, useState } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import Lenis from 'lenis'


const useLenisScroll = () => {
  const [lenis, setLenis] = useState<Lenis | null>(null)

  useEffect(() => {
    const lenisScroll = new Lenis({
      lerp: 0.07,
    })

    setLenis(lenisScroll)

    lenisScroll.on('scroll', ScrollTrigger.update)

    const onTick = (time: number) => {
      lenisScroll.raf(time * 1000)
    }
    gsap.ticker.add(onTick)
    ScrollTrigger.refresh()
    gsap.ticker.lagSmoothing(0)

    return () => {
      gsap.ticker.remove(onTick)
      lenisScroll.off('scroll', ScrollTrigger.update)
      lenisScroll.destroy()
      setLenis(null)
    }
  }, [])

  return lenis
}

export default useLenisScroll
