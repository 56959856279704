import React, { useState } from 'react'
import { Slider } from '@components'
import cn from 'classnames'
import * as st from '@assets/styl/testimonials.module.styl'
import { useLanguage } from '../hooks'
import play from '@assets/svg/play.svg'

export default () => {
  const { t } = useLanguage()
  const [visibleVideoId, setVisibleVideoId] = useState({})

  const handlePlayVideo = (index: number) => {
    setVisibleVideoId({
      [index]: !visibleVideoId[index],
    })
  }

  return (
    <section className={st.core}>
      <div>
        <div>
          <h3>{t('Testimonials.title')}</h3>
          <p>
            {t('Testimonials.paragraph')
              .split('\n')
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index <
                    t('Testimonials.paragraph').split('\n').length - 1 && (
                    <br />
                  )}
                </React.Fragment>
              ))}
          </p>
        </div>
        <Slider classes={{ arrow: st.arrow }} dots>
          {['viO_FskcPCU', 'QyJYtWsWXiE', 'mXJkO0lMJFk'].map(
            (videoId, index) => {
              const sourceVideo = `https://www.youtube.com/embed/${videoId}?autoplay=${
                visibleVideoId[index] ? '1' : '0'
              }`

              return (
                <div key={index} className={st.slide}>
                  <div
                    className={cn({ hiddenImage: visibleVideoId[index] })}
                    onClick={() => handlePlayVideo(index)}
                  >
                    <iframe
                      src={sourceVideo}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                    <div className={st.play}>
                      <img
                        src={play}
                        width={105}
                        height={106}
                        alt="Botão Play"
                      />
                    </div>
                  </div>
                  <h4>
                    {t(
                      `Testimonials.${
                        index === 0
                          ? 'spot'
                          : index === 1
                          ? 'garden'
                          : 'dimasExperience'
                      }`
                    )}
                  </h4>
                  <h5>
                    {t(
                      `Testimonials.${
                        index === 0
                          ? 'checkTestimonial'
                          : index === 1
                          ? 'visit'
                          : 'seeCustomers'
                      }`
                    )}
                  </h5>
                </div>
              )
            }
          )}
        </Slider>
      </div>
    </section>
  )
}
