import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import cn from 'classnames'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import * as st from '@assets/styl/animatedVideo.module.styl'
import maskedBar from '@assets/svg/mascara-barra.svg'
import maskedBarInverted from '@assets/svg/mascara-barra-invertida.svg'
import video from '@assets/videos/ponte.mp4'
import dBarra from '@assets/svg/d-barra.svg'

const AnimatedVideo = () => {
  const revealSectionRef = useRef<HTMLDivElement>(null)
  const revealPathRef = useRef<HTMLDivElement>(null)
  const revealLogoRef = useRef<HTMLDivElement>(null)
  const revealWrapRef = useRef<HTMLDivElement>(null)
  const revealDimasBarraRef = useRef<HTMLImageElement>(null)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: revealSectionRef.current,
        start: 'start top',
        end: 'bottom bottom',
        scrub: 1,
        onUpdate: (self) => {
          setProgress(self.progress * 200)

          if (self.progress > 0.5) {
            revealDimasBarraRef.current?.classList.add('show')
            revealLogoRef.current?.classList.add('hide')
          } else {
            revealDimasBarraRef.current?.classList.remove('show')
            revealLogoRef.current?.classList.remove('hide')
          }
        },
      },
    })
  }, [])

  return (
    <div className={st.revealSection} ref={revealSectionRef}>
      <div className={st.revealSticky}>
        <div
          className={st.revealPath}
          ref={revealPathRef}
          style={{ height: `calc(100vh - ${progress}%)` }}
        >
          <img width="1920" height="1080" src={maskedBar} />
        </div>
        <div className={st.revealWrap} ref={revealWrapRef}>
          <img
            src={dBarra}
            className={cn(st.dimasBarra, 'dimas-barra')}
            ref={revealDimasBarraRef}
          />
          <div
            className={st.revealLogo}
            ref={revealLogoRef}
            style={{ height: `${progress}%` }}
          >
            <img width="1920" height="1080" src={maskedBarInverted} />
          </div>
        </div>
        <div className={st.revealEmbedWrap}>
          <div className={st.revealEmbedContain}>
            <div className={st.revealEmbed}>
              <div className={st.video}>
                <iframe
                  width="100%"
                  height="100%"
                  src=" https://www.youtube-nocookie.com/embed/aXIgpNUhgAc?controls=0&rel=0&playsinline=1&enablejsapi=1&origin=https%3A%2F%2Fwww.dimasconstrucoes.com.br/parquedaluz/&widgetid=1&autoplay=1&loop=1&playlist=EhnLstdVNi8&mute=1"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnimatedVideo
