import React, {
  useEffect,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import { Menubar, Footer } from '@components'
import * as st from '@assets/styl/layout.module.styl'
import { translatePath } from '../translations'
import { useLenisScroll } from '../hooks'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

interface Context {
  modalOpen: number
  setModalOpen: Dispatch<SetStateAction<number>>
}

export const context = createContext<Context>(null)

const timeout = 150
const styles = {
  entering: {
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  },
}

export default ({ children, location: { pathname } }) => {
  const lenis = useLenisScroll()

  const [modalOpen, setModalOpen] = useState(-1)

  useEffect(() => {
    if (modalOpen !== -1 && lenis) {
      lenis.stop()
    } else {
      lenis?.start()
    }
  }, [modalOpen, lenis])

  useEffect(() => {
    if (lenis) {
      requestAnimationFrame(() => {
        lenis.scrollTo(0, { immediate: true })
      })
    }
  }, [pathname])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const setVW = () => {
      document.documentElement.style.setProperty(
        '--vw',
        document.documentElement.offsetWidth + 'px'
      )
    }

    window.addEventListener('resize', setVW, { signal })
    setVW()

    const observer = new ResizeObserver(() => {
      ScrollTrigger.refresh()
    })

    observer.observe(document.body)

    return () => {
      controller.abort()
      observer.unobserve(document.body)
      observer.disconnect()
    }
  }, [])

  return (
    <>
      <Menubar pathname={pathname} />
      <context.Provider
        value={{
          modalOpen,
          setModalOpen,
        }}
      >
        <div className={st.core} key={pathname}>
          {children}
        </div>
      </context.Provider>

      {pathname !== translatePath('/campanha-de-indicacao/') && <Footer />}
    </>
  )
}
