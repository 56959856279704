import React, { useEffect, useState, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import * as st from '@assets/styl/differential.module.styl'
import star from '@assets/svg/star-black.svg'
import cn from 'classnames'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'

import img1 from '@assets/img/differential-scroll-01.jpg'
import img2 from '@assets/img/differential-scroll-02.jpg'
import img3 from '@assets/img/differential-scroll-03.jpg'
import img4 from '@assets/img/differential-scroll-04.jpg'

import logo01 from '@assets/img/tecnologias/bimcollab.png'
import logo02 from '@assets/img/tecnologias/biomtech.png'
import logo03 from '@assets/img/tecnologias/construcode.png'
import logo04 from '@assets/img/tecnologias/construmanager.png'
import logo05 from '@assets/img/tecnologias/d4sign.png'
import logo06 from '@assets/img/tecnologias/feedz.png'
import logo07 from '@assets/img/tecnologias/hinc.png'
import logo08 from '@assets/img/tecnologias/jira.png'
import logo09 from '@assets/img/tecnologias/navisworks.png'
import logo10 from '@assets/img/tecnologias/navisworks.png'
import logo11 from '@assets/img/tecnologias/nexxera.png'
import logo12 from '@assets/img/tecnologias/office.png'
import logo13 from '@assets/img/tecnologias/ponto-mais.png'
import logo14 from '@assets/img/tecnologias/power-bi.png'
import logo15 from '@assets/img/tecnologias/rd-station.png'
import logo16 from '@assets/img/tecnologias/revit.png'
import logo17 from '@assets/img/tecnologias/seif.png'
import logo18 from '@assets/img/tecnologias/sienge.png'
import logo19 from '@assets/img/tecnologias/winker.png'

import { useLanguage } from '../hooks'

gsap.registerPlugin(ScrollTrigger)

const DifferentialScrollSection = () => {
  const { t } = useLanguage()
  const sectionRef = useRef<HTMLDivElement | null>(null)
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const [isMobile, setIsMobile] = useState(false)
  const swiperRef = useRef<any>(null)
  const tl = useRef<gsap.core.Timeline | null>(null)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1199)
    }

    window.addEventListener('resize', handleResize, { signal })
    handleResize()

    return () => controller.abort()
  }, [])

  const items = [
    {
      src: img1,
      number: '01',
      title: [t('Differential.modeling.1')],
      text: [t('Differential.modelingDescription.1')],
    },
    {
      src: img2,
      number: '02',
      title: t('Differential.usedTech'),
      text: [
        <div className={st.logos}>
          <ul>
            <li>
              <span>
                <img src={logo01} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo02} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo03} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo04} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo05} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo06} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo07} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo08} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo09} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo10} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo11} alt="Logo" width={75} height={75} />
              </span>
            </li>
            {/* <li>
              <span>
                <img src={logo12} alt="Logo" width={75} height={75} />
              </span>
            </li> */}
            <li>
              <span>
                <img src={logo13} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo14} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo15} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo16} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo17} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo18} alt="Logo" width={75} height={75} />
              </span>
            </li>
            <li>
              <span>
                <img src={logo19} alt="Logo" width={75} height={75} />
              </span>
            </li>
          </ul>
        </div>,
      ],
    },
    {
      src: img3,
      number: '03',
      title: [
        t('Differential.differentialWork.1'),
        <br />,
        t('Differential.differentialWork.2'),
      ],
      text: [
        <ul className={st.styledList}>
          <li>{t('Differential.differentialWorkDescription.1')}</li>
          <li>{t('Differential.differentialWorkDescription.2')}</li>
          <li>{t('Differential.differentialWorkDescription.3')}</li>
          <li>{t('Differential.differentialWorkDescription.4')}</li>
        </ul>,
      ],
    },
    {
      src: img4,
      number: '04',
      title: [
        t('Differential.differentialWorkSell.1'),
        <br />,
        t('Differential.differentialWorkSell.2'),
      ],
      text: [
        <ul className={st.styledList}>
          <li>{t('Differential.differentialWorkSellDescription.1')}</li>
          <li>{t('Differential.differentialWorkSellDescription.2')}</li>
          <li>{t('Differential.differentialWorkSellDescription.3')}</li>
          <li>{t('Differential.differentialWorkSellDescription.4')}</li>
          <li>{t('Differential.differentialWorkSellDescription.5')}</li>
          <li>{t('Differential.differentialWorkSellDescription.6')}</li>
        </ul>,
      ],
    },
  ]

  useEffect(() => {
    if (!sectionRef.current || !scrollRef.current) return

    if (window.innerWidth > 1199) {
      const horizontalScrollWidth = scrollRef.current.scrollWidth
      const visibleWidth = scrollRef.current.offsetWidth
      if (!horizontalScrollWidth) return

      const paddingOffset = 120 * 2
      const scrollDistance =
        horizontalScrollWidth - visibleWidth + paddingOffset

      const startValue = window.innerWidth < 1600 ? '10% top' : 'top top'

      if (tl.current) {
        tl.current.kill()
      }

      tl.current = gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          pin: true,
          start: startValue,
          scrub: 1,
          pinnedContainer: sectionRef.current,
          end: `+=${scrollDistance}`,
          invalidateOnRefresh: true,
        },
      })

      tl.current.to(scrollRef.current, {
        x: -scrollDistance,
        ease: 'none',
      })
    }
  }, [isMobile])

  return (
    <>
      <section ref={sectionRef} className={st.sectionContainer}>
        {isMobile ? (
          <>
            <div className={st.sectionTitle}>
              <img
                src={star}
                alt="Imagem do asterisco"
                width="82"
                height="82"
              />
              <h2>
                {t('Differential.title.1')}
                <br />
                <b>{t('Differential.title.2')}</b>
                <br />
                <b>{t('Differential.title.3')}</b>
                <br />
                {t('Differential.title.4')}
              </h2>
            </div>
            <Swiper
              ref={swiperRef}
              breakpoints={{
                320: {
                  slidesPerView: 1.2,
                },
                767: {
                  slidesPerView: 2.2,
                },
              }}
              className={st.slider}
            >
              {items.map(({ src, title, number, text }, index) => {
                const isEven = index % 2 === 0
                return (
                  <SwiperSlide key={index}>
                    <div className={cn(st.item)}>
                      {isEven ? (
                        <>
                          <img
                            src={src}
                            alt="Imagens do scroll"
                            className={st.imageContainer}
                          />
                          <div className={st.textContainer}>
                            <span>{number}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={st.textContainer}>
                            <span>{number}</span>
                          </div>
                          <img
                            src={src}
                            alt="Imagens do scroll"
                            className={st.imageContainer}
                          />
                        </>
                      )}

                      <div className={st.textContainer}>
                        <h4>{title}</h4>
                        <p>{text}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </>
        ) : (
          <div ref={scrollRef} className={st.scrollContainer}>
            <div className={st.sectionTitle}>
              <img
                src={star}
                alt="Imagem do asterisco"
                width="82"
                height="82"
              />
              <h2>
                {t('Differential.title.1')}
                <br />
                <b>{t('Differential.title.2')}</b>
                <br />
                <b>{t('Differential.title.3')}</b>
                <br />
                {t('Differential.title.4')}
              </h2>
            </div>
            <div className={st.sectionContent}>
              {items.map(({ src, title, number, text }, index) => {
                const isEven = index % 2 === 0

                return (
                  <div className={cn(st.item)} key={index}>
                    {isEven ? (
                      <>
                        <img
                          src={src}
                          alt="Imagens do scroll"
                          className={st.imageContainer}
                        />
                        <div className={st.textContainer}>
                          <span>{number}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={st.textContainer}>
                          <span>{number}</span>
                        </div>
                        <img
                          src={src}
                          alt="Imagens do scroll"
                          className={st.imageContainer}
                        />
                      </>
                    )}

                    <div className={st.textContainer}>
                      <h4>{title}</h4>
                      <p>{text}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </section>
    </>
  )
}

export default DifferentialScrollSection
