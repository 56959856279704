import React from 'react'
import { useLanguage } from '../hooks'
import cn from 'classnames'

import * as st from '@assets/styl/upcomingProjects.module.styl'

export type UpcomingProjectProps = {
  // facades: string[]
  facade: string
  facadeMobile: string
  facadeHover: string
  facadeHome: string
  facadeHomeMobile: string
  facadeHomeHover: string
  name: string
  title: string
  description: string
  neighborhood: string
  city: string
  room: string
  tag: string
  url: string
  feature: string
}

const UpcomingProject = ({
  // facades,
  facade,
  facadeMobile,
  facadeHover,
  facadeHome,
  facadeHomeMobile,
  facadeHomeHover,
  name,
  title,
  description,
  neighborhood,
  city,
  room,
  tag,
  url,
  feature,
}: UpcomingProjectProps) => {
  const { t } = useLanguage()

  const safeTitle = title || ''
  const safeDescription = description || ''

  return (
    <div className={st.core}>
      <div className={st.header}>
        <h3>{name}</h3>
        <p>
          <b>{tag}</b> / {neighborhood} / {city}
        </p>
      </div>

      {facade && (
        <div className={st.cover}>
          <img src={facade} alt={`Fachada 1`} loading="lazy" />
        </div>
      )}

      <div className={st.details}>
        {facadeHome && (
          <div className={st.image}>
            <img src={facadeHome} alt="Fachada 2" loading="lazy" />
          </div>
        )}
        <div className={st.content}>
          <div className={st.description}>
            <h4
              dangerouslySetInnerHTML={{ __html: safeTitle.replace(/\n/g, '<br>') }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: safeDescription.replace(/\n/g, '<br>'),
              }}
            />
          </div>
          <a
            className="btn light"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Releases.visitWebsite')}
          </a>
        </div>
      </div>
    </div>
  )
}

export default UpcomingProject
